
import { Link } from 'react-router-dom';
import S from './style'

import TranslateStatic from '../../locales/TranslateStatic';
import Mete from '../../assets/ekip/mete.jpg'
import Batu from '../../assets/ekip/batu.jpg'
import Adil from '../../assets/ekip/adil.png'
import Suat from '../../assets/ekip/suat.png'
import Ayse from '../../assets/ekip/aysekendirci.png'
import Aysenur from '../../assets/ekip/aysenururfan.png'
import Gamze from '../../assets/ekip/gamze.png'
import Amilya from '../../assets/ekip/amilya.jpg'
import Senem from '../../assets/ekip/senem.jpeg'
import Kifayet from '../../assets/ekip/MAHARRAMOVA.jpg'
import Oznur from '../../assets/ekip/oznursahin.png'
import Yeliz from '../../assets/ekip/yelizkeskin.png'
import Rabia from '../../assets/ekip/rabia.png'
import Suzan from '../../assets/ekip/suzanna.jpg'
import Ozlem from '../../assets/ekip/ozlem.jpeg'
import Semra from '../../assets/ekip/semra.jpeg'
import Safinur from '../../assets/ekip/safinur.png'
import Hulya from '../../assets/ekip/hulya.png'
import Levent from '../../assets/ekip/levent.png'
import Azime from '../../assets/ekip/azime-evci.png'
import Burcu from '../../assets/ekip/burcu.jpeg'
import Ceren from '../../assets/ekip/ceren.png'
import Avatar from '../../assets/ekip/user.png'
import Ozge from '../../assets/ekip/ozge.jpeg'
export function Team() {
  return (
    <S.Wrapper>
      {/* <S.Circle/> */}
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="teamHeader" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <TranslateStatic string="teamHeader" />
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
       <Certification/>
      </S.Sertifika> */}
      <S.Section>
        <S.Baslik><TranslateStatic string="teamHeader" /></S.Baslik>
        <S.Izgara>
          <div className='rowi'>
            <div className='items'>
              <img src={Mete}></img>
              <h2><TranslateStatic string="MeteUnvan" /> Mete Işıkoğlu <TranslateStatic string="TitreMD" /></h2>
              <div className='content'>
                <p><TranslateStatic string="MeteItitre" /></p>
                <p><TranslateStatic string="UyteSorumlusu" /></p>
                <article><TranslateStatic string="MeteIsikoglu" /></article>
                <article><TranslateStatic string="MeteIsikoglu1" /></article>
                <Link to='/meteisikoglu'><TranslateStatic string="Ayrintilar" /></Link>
              </div>

            </div>
          </div>
          <div className='rowi'>
            <div className='items'>
              <img src={Batu}></img>
              <h2><TranslateStatic string="BatuUnvan" /> Dr. Batu Aydınuraz <TranslateStatic string="TitreMD" /></h2>
              <div className='content'>
                {/* <p>Mes'ul Müdür</p> */}
                <article><TranslateStatic string="BatuAydinuraz" /></article>
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}
                <Link to='/batuaydinuraz'><TranslateStatic string="Ayrintilar" /></Link>
              </div>

            </div>
            <div className='items'>
              <img src={Adil}></img>
              <h2>Prof. Dr. Adil Gökalp</h2>
              <div className='content'>
                {/* <p>Mes'ul Müdür</p> */}
                <article><TranslateStatic string="Adil" /></article>
                <Link to='/adilgökalp'><TranslateStatic string="Ayrintilar" /></Link>
              </div>

            </div>
            <div className='items'>
              <img src={Azime}></img>
              <h2><TranslateStatic string="AzimeUnvan" /> Dr. Azime Evci</h2>
              <div className='content'>
                {/* <p>Mes'ul Müdür</p> */}
                <article><TranslateStatic string="Azime" /></article>
                <Link to='/azime'><TranslateStatic string="Ayrintilar" /></Link>

              </div>

            </div>
          </div>
          <div className='rowi'>
            <div className='items'>
              <img src={Ayse}></img>
              <h2>Dr. Ayşe Kendirci Çeviren</h2>
              <div className='content'>
                <article><TranslateStatic string="Ayse" /></article>
                {/* <article>Kadın Hastalıkları ve Doğum Uzmanı</article> */}
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}
                <Link to='/aysekendirciceviren'><TranslateStatic string="Ayrintilar" /></Link>
              </div>

            </div>
            <div className='items'>
              <img src={Aysenur}></img>
              <h2>Ayşenur Avcı</h2>
              <div className='content'>
                <article><TranslateStatic string="Aysenur" /></article>
                {/* <article>Üroloji Uzmanı</article> */}
                <Link to='/aysenuravci'><TranslateStatic string="Ayrintilar" /></Link>

              </div>

            </div>
            <div className='items'>
              <img src={Ceren}></img>
              <h2>Ceren Altınöz</h2>
              <div className='content'>
                <article><TranslateStatic string="Gamze" /></article>
                {/* <article>Lab. Teknikeri</article> */}

              </div>

            </div>
            <div className='items'>
              <img src={Ozge}></img>
              <h2>Özge Rabia Özkaya</h2>
              <div className='content'>
                <article><TranslateStatic string="OzgeRabia" /></article>
                {/* <article>Lab. Teknikeri</article> */}

              </div>

            </div>
          </div>
          <div className='rowi'>
            <div className='items'>
              <img src={Amilya}></img>
              <h2>Amilya Kibar</h2>
              <div className='content'>
                <article><TranslateStatic string="Amilya" /></article>
                {/* <article>Kadın Hastalıkları ve Doğum Uzmanı</article> */}
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}
              </div>

            </div>
            <div className='items'>
              <img src={Senem}></img>
              <h2>Senem Aslanbaş</h2>
              <div className='content'>
                <article><TranslateStatic string="Amilya" /></article>
                {/* <article>Üroloji Uzmanı</article> */}

              </div>

            </div>
            <div className='items'>
              <img src={Avatar}></img>
              <h2>Ebral Çubukçu</h2>
              <div className='content'>
                <article><TranslateStatic string="Amilya" /></article>
                {/* <article>Lab. Teknikeri</article> */}

              </div>

            </div>
          </div>
          <div className='rowi'>
            <div className='items'>
              <img src={Yeliz}></img>
              <h2>Yeliz Gelen</h2>
              <div className='content'>
                <article><TranslateStatic string="Yeliz" /></article>
                {/* <article>Kadın Hastalıkları ve Doğum Uzmanı</article> */}
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}
              </div>

            </div>
            <div className='items'>
              <img src={Oznur}></img>
              <h2>Öznur Şahin Kotanak</h2>
              <div className='content'>
                <article><TranslateStatic string="Yeliz" /></article>
                {/* <article>Üroloji Uzmanı</article> */}

              </div>

            </div>

          </div>
          <div className='rowi'>
            <div className='items'>
              <img src={Avatar}></img>
              <h2>Sahra Nur Yılmaz</h2>
              <div className='content'>
                <article><TranslateStatic string="Rabia" /></article>
                {/* <article>Kadın Hastalıkları ve Doğum Uzmanı</article> */}
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}
              </div>

            </div>
      

          </div>
          <div className='rowi'>
           
            <div className='items'>
              <img src={Ozlem}></img>
              <h2>Özlem Demir</h2>
              <div className='content'>
                <article><TranslateStatic string="Ev idaresi" /></article>
                {/* <article>Üroloji Uzmanı</article> */}

              </div>

            </div>
            <div className='items'>
              <img src={Semra}></img>
              <h2>Semra Köse</h2>
              <div className='content'>
                <article><TranslateStatic string="Ev idaresi" /></article>
                {/* <article>Lab. Teknikeri</article> */}

              </div>

            </div>
          </div>
          <div className='rowi'>
            <div className='items'>
              <img src={Safinur}></img>
              <h2>Safinur Postacıoğlu</h2>
              <div className='content'>
                <article><TranslateStatic string="Kurumsal iletisim" /></article>
                {/* <article>Kadın Hastalıkları ve Doğum Uzmanı</article> */}
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}
              </div>

            </div>


          </div>
          <div className='rowi'>
            <div className='items'>
              <img src={Hulya}></img>
              <h2>Hülya Kara</h2>
              <div className='content'>
                <article><TranslateStatic string="Hulya" /></article>
                {/* <article>Kadın Hastalıkları ve Doğum Uzmanı</article> */}
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}
              </div>
            </div>

            <div className='items'>
              <img src={Levent}></img>
              <h2>Av. Levent Geçerler</h2>
              <div className='content'>
                <article><TranslateStatic string="Levent" /></article>
                {/* <article>Kadın Hastalıkları ve Doğum Uzmanı</article> */}
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}
              </div>

            </div>

          </div>
        </S.Izgara>

      </S.Section>
    </S.Wrapper>
  );
}
