import { YouTubePlaylist } from "@codesweetly/react-youtube-playlist";
import S from "./style";
import TranslateStatic from '../../locales/TranslateStatic'
import { Link } from "react-router-dom";
export default function () {
  return (
    <S.Wrapper>
      <S.Sol>
        <a>
          <p>
            <b>
              {/* <TranslateStatic string="Webinar" /> */}
              Youtube
            </b>
            {/* <TranslateStatic string="EgitimDestekLow2" /> */}
          </p>
        </a>

      </S.Sol>
      <S.Sag>
        <Link to="youtube" className="saydam">
        </Link>

        <YouTubePlaylist
          apiKey="AIzaSyB-bOMjkCW9qV8Pmy2CwMoPtoZUeQP0hdw"
          playlistId="PLLTGKC6AlQ_JrT_82BEQcTLkad2Z3ypBe"
        
        />
      </S.Sag>
    </S.Wrapper>
  );
}

