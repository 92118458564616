import React, { useState, useEffect } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import { HomePage } from './pages/Home';
import { PageTwo } from './pages/Page2';
import { Product } from './pages/Product';
import { Services } from './pages/Services';

import { Iletisim } from './pages/ContactUs';

import { Reference } from './pages/References';
import { Category } from './pages/Category';
import { SubCategory } from './pages/SubCategory';
import { Cookie } from './pages/Cookie';
import { Cer } from './pages/Certification';
import { Catalog } from './pages/catalog';
import { Teklif } from './pages/teklif';

import Header from './components/header'
import Footer from './components/footer';
import landChecker from './utils/langChecker';
import {Team} from './pages/Team';
import {Yumurtlama} from './pages/Yumurtlama';
import {GenetikTarama} from './pages/GenetikTarama';
import {Asilama} from './pages/Asilama';
import {DondurarakSaklama} from './pages/DondurarakSaklama';
import {Endoskopik} from './pages/Endoskopik';
import {Tesa} from './pages/Tesa';
import {TupBebek} from './pages/TupBebek';
import { EgitimeDestek } from './pages/EgitimeDestek';
import { UremeGuclugu } from './pages/UremeGuclugu';
import { SSS } from './pages/SSS'
import { EkTedaviler } from './pages/EkTedaviler';
import { Mete } from './pages/MeteIsikoglu';
import { Batu } from './pages/Batuaydinuraz';
import { Ayse } from './pages/AyseKendirci';
import { Kvkk } from './pages/kvkk';
import { AboutUs } from './pages/aboutus';
import { Aysenur } from './pages/aysenuravci';
import { TedaviSureci } from './pages/tedaviSureci';
import { Azime } from './pages/azime';
import { Adil } from './pages/adil';
import { Quiz } from './pages/Quiz';
import { GebelikBakimi } from './pages/GebelikBakimi';
import { Ilac } from './pages/ilac';
import { Youtube } from './pages/youtube';

export default (props) => {


   return < Routes lang={props.lang} >
        <Route path="/" element={<Layout lang={props.lang} changeLanguage={props.changeLanguage}/>}>
            <Route path="/" index element={<HomePage/>} />
            {/* <Route path="/aboutus" element={<PageTwo/>} /> */}
            <Route path="/yumurtlama" element={<Yumurtlama/>} />
            <Route path="/genetiktarama" element={<GenetikTarama/>} />
            <Route path="/asilama" element={<Asilama/>} />
            <Route path="/donduraraksaklama" element={<DondurarakSaklama/>} />
            <Route path="/endoskopikameliyatlar" element={<Endoskopik/>} />
            <Route path="/tesa" element={<Tesa/>} />
            <Route path="/tupbebek" element={<TupBebek/>} />
            <Route path="/services" element={<Services/>} />
            <Route path="/product/:catid/:subcatid/:link" element={<Product/>} />
            <Route path="/references" element={<Reference/>} />
            <Route path="/contactus" element={<Iletisim/>} />
            <Route path="/offer" element={<Teklif/>} />
            <Route path="/catalog" element={<Catalog/>} />
            <Route path="/category" element={<Category/>} />
            <Route path="/cookie" element={<Cookie/>} />
            <Route path="/team" element={<Team/>} />
            <Route path="/support" element={<EgitimeDestek/>} />
            <Route path="/category/:name/:id/:index" element={<SubCategory/>} />
            <Route path="/uremeguclugu" element={<UremeGuclugu/>} />
            <Route path="/sss" element={<SSS/>} />
            <Route path="/ekt" element={<EkTedaviler/>} />
            <Route path="/meteisikoglu" element={<Mete/>} />
            <Route path="/batuaydinuraz" element={<Batu/>} />
            <Route path="/aysekendirciceviren" element={<Ayse/>} />
            <Route path="/kvkk" element={<Kvkk/>} />
            <Route path="/aboutus" element={<AboutUs/>} />
            <Route path="/aysenuravci" element={<Aysenur/>} />
            <Route path="/tedavisureci" element={<TedaviSureci/>} />
            <Route path="/azime" element={<Azime/>} />
            <Route path="/adilgökalp" element={<Adil/>} />
            <Route path="/quiz" element={<Quiz/>} />
            <Route path="/takip" element={<GebelikBakimi/>} />
            <Route path="/ilac" element={<Ilac/>} />
            <Route path="/youtube" element={<Youtube/>} />
        </Route>
    </Routes >

};

function Layout(props) {
    // const [preferredLocale, setPreferredLocale] = useState("tr");
    // function changeLanguage(event) {
    //     setPreferredLocale(event.target.value)
    // };
    // useEffect(() => {
    //     setPreferredLocale(landChecker);
    // }, []);
    const { lang } = props;
    console.log(lang)
    return (<>
        <Header lang={props.lang} changeLanguage={props.changeLanguage} />
        <Outlet />
        
        <Footer />
    </>
    );
}