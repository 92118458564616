import React, { useState } from 'react';
import S from './style'
import Logo from '../../assets/logo.svg'
import Insta from '../../assets/instagram.png'
import Whatsapp from '../../assets/whatsapp.png'
import TranslateStatic from '../../locales/TranslateStatic'
import Subscribe from '../subscribe';
import { Link } from 'react-router-dom';
import { LocaleContext } from '../../locale-context';
import axios from "axios";
import PopupC from './popup';
function Langmini(props) {

    return <LocaleContext.Consumer>
        {(value) => {
            if (value === "en") {
                return <><a href='https://www.instagram.com/gelecekivf/' target='_blank'>
                    <img src={Insta} />

                </a>
                    <a href="whatsapp://send?phone=+447801684280">

                        <img src={Whatsapp} />
                    </a>
                </>
            }
            else if (value === "tr") {
                return <><a href='https://www.instagram.com/antalyagelecektupbebek/' target='_blank'>
                    <img src={Insta} />

                </a>
                    <a href="whatsapp://send?phone=+905532540057">

                        <img src={Whatsapp} />
                    </a>
                </>
            }
            else if (value === "ru") {
                return <><a href='https://www.instagram.com/antalyagelecektupbebek/' target='_blank'>
                    <img src={Insta} />

                </a>
                    <a href="whatsapp://send?phone=+905532540057">

                        <img src={Whatsapp} />
                    </a>
                </>
            }
        }}


    </LocaleContext.Consumer>
}
function Footer(props) {
    const URL = "http://localhost:3000/api"
    // const URL = "https://app.gelecektupbebek.com.tr/api"
    const placer = <TranslateStatic string="footerMini1" />
    console.log(placer)
    const [phone, setphone] = useState("")
    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState(false);
    const closeModal = () => setOpen(false);
    const closeModalError = () => setOpenError(false);
    function Gonder() {
        // axios.post('http://localhost:3000/api/test')
        const article = { phone: phone };
        axios.post(URL+'/mini', article)
            .then(response => {
                setOpen(o => !o)
                console.log(response)

            }

            ).catch((err) => {
                openError(o => !o)
                console.log(err)
            });
        // axios.get(`http://localhost:3000/api/test`).then((response) => {
        //     // setPokemon(response.data);
        //     console.log(response.data);
        //     // setLoading(false);
        // });
        // if (phone) {
        //     fetch("http://localhost:3000/api/mini", {
        //       method: "POST",
        //       body: JSON.stringify({
        //         phone
        //       }),
        //       headers: {
        //         "Content-type": "application/json; charset=UTF-8",
        //       },
        //     })
        //       .then(response => response.json())
        //     //   .then(data => {
        //     //     setphone("")
        //     //     console.log("Gitti")
        //     //   })
        // fetch('http://localhost:3000/api/test',{
        //     mode: 'cors',
        //     method: 'GET',
        //     headers: {'Content-Type':'application/json'},
        //     // body: JSON.stringify({
        //     //   username: user,
        //     //   password: pass,
        //     // })
        //   })
        //     .then((res) => {
        //         return res.json();
        //     })
        //     .then((data) => {
        //         console.log(data);
        //         // setPhotos(data);
        //     });

    }
    function handleNumbers(evt) {
        setphone(evt.target.validity.valid ? evt.target.value : phone);
    }
    return (
        <div>

            {/* <Subscribe/> */}
            <S.Wrapper>
            <PopupC open={open} closeModal={closeModal} openError={openError} closeModalError={closeModalError}/>
                <S.Memnun>
                    <div className='main'>
                        {/* <Link>Memnuniyet Anketi</Link> */}
                        <S.Tex>

                            <h3>
                                <TranslateStatic string="footerMini" />
                            </h3>
                            <p>
                                <TranslateStatic string="footerMini1" />
                            </p>
                        </S.Tex>
                        <S.Email>
                            <input
                                type='tel'
                                placeholder="(123) 456-7890"
                                pattern="[0-9]*"
                                value={phone}
                                name="phone"
                                onChange={handleNumbers}
                            // onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                            />
                            <button onClick={Gonder}><TranslateStatic string="footerGonder" /></button>
                        </S.Email>
                    </div>
                </S.Memnun>
                <S.Ust>
                    <div>
                        <S.Logo src={Logo} alt="gelecek logo" />
                        <p><TranslateStatic string="footerLogoContent" /></p>
                        <div className='socialMedia'>
                            <Langmini />

                        </div>
                    </div>
                    <div>
                        <h2><TranslateStatic string="contactH2" /></h2>
                        <ul>
                            <li><b><TranslateStatic string="contactAddress" /></b> : Çağlayan Mah. Bülent Ecevit Bulvarı No:167 Lara / <b>ANTALYA</b></li>
                            <li><b><TranslateStatic string="contactMail" /></b> : <TranslateStatic string="contactMailValue" /> </li>
                            <li><b><TranslateStatic string="contactPhone" /></b> : <TranslateStatic string="footerTel" /></li>
                        </ul>
                        <br></br>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12769.011760042345!2d30.779435!3d36.860364!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c39b1e0faef71b%3A0xf97f4dde9de8a712!2sGELECEK%20T%C3%9CP%20BEBEK%20MERKEZ%C4%B0!5e0!3m2!1str!2str!4v1708598447945!5m2!1str!2str"
                            allowfullscreen="" loading="lazy">

                        </iframe>
                    </div>
                </S.Ust>
                <div className="copyright">
                    <span><Link to="kvkk"><TranslateStatic string="contactCopyright" /></Link></span>
                    <span><TranslateStatic string="contactWebAuthor" /> : Eren EROZAN</span>
                </div>
            </S.Wrapper>
        </div>
    );
}

export default Footer;


