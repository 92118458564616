
import { Link } from 'react-router-dom';
import S from './style'
import { LocaleContext } from '../../locale-context';
import TranslateStatic from '../../locales/TranslateStatic';
import YumurtlamaImg from '../../assets/endoskopik.jpeg'

import { YouTubePlaylist } from "@codesweetly/react-youtube-playlist";

export function Youtube() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <Link>Youtube</Link>
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <div className='banner'>
          <div className='baslik'>
            <h1>
              Youtube
            </h1>
          </div>
        </div>
        <div className='content'>

          <div className='texts'>
            <p><TranslateStatic string="IlacAnlatimiMetin" /></p>

          </div>
          {/* <h3>360° Kliniğimiz</h3> */}
          <YouTubePlaylist
            apiKey="AIzaSyB-bOMjkCW9qV8Pmy2CwMoPtoZUeQP0hdw"
            playlistId="PLLTGKC6AlQ_JrT_82BEQcTLkad2Z3ypBe"
          />
          {/* <LocaleContext.Consumer>
            {(value) => {
              if (value === "tr") {
                return <>
                  <div className='mapContainer'>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/D2LrVl--QXk?si=a5v9CAaon8Elt1nl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/PgJRTXlm5hk?si=ofeBt7EQrdfYM0pk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/0dEpUkc6sfQ?si=xO8_JOl5uOS6725m" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/AV4f4aBhEZY?si=wrEy74qS1llRMOc8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ibApofPlwFg?si=QsO-by4mZQyhvc1_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/DBoqFh6U7yc?si=H1RoJRPhPMSBEeiq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/8g7C6YCSJAs?si=Ng6NsME9PoONkwjB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/0MRou2y9Y4E?si=FgKPmtw-dV8v4qvi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
                </>
              }
              else if (value === "ru") {
                return <>
                  <div className='mapContainer'>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/-dRhSF8e7fM?si=Ycpbwm--LOTekBbB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/ci17xrnyvug?si=zXK3sGexkryw-aRW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/VFe39t31kR4?si=2Sb_lMzIjgtEzQVU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/OevnnFDNc2o?si=ODuYSc11xzf8ow7L" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
                </>
              }
            }}
          </LocaleContext.Consumer> */}
        </div>


      </S.Section>
    </S.Wrapper >
  );
}
